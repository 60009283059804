@-ms-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;500;600;700&display=swap'); */

* {
  margin: 0;
  padding: 0;
  outline: none;
}

/* Global Styles */
body {
  font-size: 18px;
  line-height: 30px;
  color: #313131;
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}
body.hidden,
html.hi-dpi.hidden {
  position: relative;
  overflow: hidden !important;
}

html,
body {
  height: 100%;
  -ms-overflow-style: none !important;
  background: #ffffff;
}

.bathroomGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.bathroomImg {
  width: 400px;
  padding: 3px;
}
.bathroomImg img {
  width: 100%;
  height: 100%;
}
.bathroomImg:hover {
  cursor: pointer;
}

input,
textarea,
select,
button,
fieldset {
  font-family: "Quicksand", sans-serif;
  font-size: 11px;
  -webkit-appearance: none;
  border-radius: 0;
  color: #b0b0b0;
  background: transparent;
  border: 0;
}
input:active,
textarea:active {
  box-shadow: none;
}
ul,
ol {
  list-style: none outside none;
}

a img {
  border: 0;
}

a,
button {
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

h1 {
  color: #424242;
  font-size: 70px;
  line-height: 74px;
  font-weight: 300;
  text-shadow: 3.993px 3.009px 2px rgba(216, 216, 216, 0.9);
}
h2 {
  color: #313131;
  font-size: 65px;
  line-height: 64px;
  font-weight: 300;
  text-shadow: 3.993px 3.009px 2px rgba(216, 216, 216, 0.9);
}
h3 {
  color: #424242;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  font-family: "Quicksand", serif;
}

.left,
.alignleft {
  float: left;
  display: inline;
}
.right,
.alignright {
  float: right;
  display: inline;
}

.cl {
  font-size: 0;
  line-height: 0;
  clear: both;
  display: block;
  height: 0;
}

.clearfix {
  *zoom: 1;
}
.clearfix:after {
  content: "";
  width: 100%;
  height: 0;
  display: block;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  clear: both;
}

.desktop-btn {
  white-space: nowrap;
}

.blue-btn {
  padding: 15px 40px;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  display: table;
  border-radius: 4px;
  /* background-color: rgb(70, 191, 238);
  background: linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 220, 224) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 220, 224) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 220, 224) 100%
  ); */
  background-color: rgb(70, 191, 238);
  background: linear-gradient(90deg, rgb(70, 191, 238) 0%, rgb(41, 220, 224) 100%);
  transition: all 0.2s;
  text-decoration: none;
  font-weight: 600;
}
.blue-btn:hover {
  background: linear-gradient(90deg, rgb(70, 191, 238) 0%, rgb(41, 220, 224) 100%);
  /* background-image: -webkit-linear-gradient(
    -90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 220, 224) 100%
  ); */
}

.blue-btn.cta {
  background-color: rgb(255 169 11);
  background: linear-gradient(90deg, rgb(229 148 0) 0%, rgb(255 169 11) 100%);
}
.blue-btn.cta:hover {
  background: linear-gradient(90deg, rgb(238, 155, 1) 0%, rgb(255, 180, 7) 100%);
}

.blue-btn.ctaB {
  background-color: rgb(29 60 90);
  background: linear-gradient(90deg, rgb(20 47 74) 0%, rgb(22 84 144) 100%);
}
.blue-btn.ctaB:hover {
  background: linear-gradient(90deg, rgb(22 53 83) 0%, rgb(18 95 169) 100%);
}

.empty-btn {
  padding: 14px 40px;
  font-size: 20px;
  line-height: 30px;
  color: #5a676b;
  display: table;
  border-radius: 4px;
  background: #ffffff;
  border: solid 1px #35bfc8;
  transition: all 0.2s;
  font-weight: 600;
}

@media screen and (max-width:1367px) {    
  .empty-btn {
    font-size: 16px;
  }
}
.empty-btn:hover {
  color: #ffffff;
  /* background: #35bfc8; */
  background: linear-gradient(90deg, rgb(229 148 0) 0%, rgb(255 169 11) 100%);
  /* background: linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 220, 224) 100%
  ); */
}

.wrapper {
  position: relative;
  min-height: 100%;
  height: auto !important;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  display: block;
  padding: 0 20px;
}

@media only screen and (max-width: 1220px) {
  h1 {
    font-size: 60px;
    line-height: 64px;
  }
  h2 {
    font-size: 50px;
    line-height: 54px;
  }
  h3 {
    font-size: 30px;
    line-height: 34px;
  }
}

@media only screen and (max-width: 989px) {
  h1 {
    font-size: 50px;
    line-height: 54px;
  }
  h2 {
    font-size: 40px;
    line-height: 44px;
  }
  h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .bathroomImg {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 35px;
    line-height: 39px;
  }
  h2 {
    font-size: 30px;
    line-height: 34px;
  }
  h3 {
    font-size: 20px;
    line-height: 24px;
  }

  .latest-works h2 {
    font-size: 25px;
  }
}

/* Latest Works */
.latest-works {
  position: relative;
  padding: 0 0 50px 0;
  text-align: center;
  
}
.latest-works:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 580px;
  height: 249px;
  background: url(./Assets/images/grid.png) repeat 0 0;
  display: block;
  z-index: -1;
}
.latest-works h1 {  
  font-size: 34pt;
}
.latest-works h2 {
  padding: 90px 0;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 989px) {
  .latest-works {
    margin-top:1rem;
  }
  .latest-works h2 {
    padding: 10px 0 !important;
  }
}

.works-slider {
  position: relative;
  height: 570px;
  width: 100%;
  overflow: hidden;
}
.works-slider.mtp .slick-track{
  height: 390px;
  overflow: hidden;
}

.latest-work-slider-item {
  height: 570px;
  width: 100%;
}
.latest-work-slider-item.video {
  height: 270px;
  margin-top: 50px;
  width: 100%;
}
.latest-work-slider-item > div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.works-slider:after {
  content: "";
  display: block;
  clear: both;
}
.works-slider img {
  width: 100%;
  height: auto;
  display: block;
}
.works-slider .owl-item {
  float: left;
}
.works-slider .owl-prev,
.works-slider .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100px;
  height: 100px;
  background: #ffffff;
  font-size: 0;
  line-height: 0;
  text-indent: -100%;
}
.works-slider .owl-prev:after,
.works-slider .owl-next:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35px;
  height: 21px;
  margin: -10px 0 0 -17px;
  display: block;
  background: url(./Assets/images/slider_nav.png) no-repeat 0 0;
  transition: all 0.2s;
}
.works-slider .owl-prev {
  left: 0;
}
.works-slider .owl-prev:after {
  background-position: 0 0;
}
.works-slider .owl-next {
  right: 0;
}
.works-slider .owl-next:after {
  background-position: 0 bottom;
}
.works-slider .owl-prev:hover:after,
.works-slider .owl-next:hover:after {
  transform: scale(1.1);
}

@media only screen and (max-width: 1800px) {
  .works-slider {
    max-height: 570px;
    height: 100%;
  }
}

/* Home Start */
.home-start {
  padding: 130px 0;
  border-top: solid 1px #f7f7f7;
  background: url(./Assets/images/lets_get_started.png) no-repeat right center;
  text-align: left;
}
.home-start.blog{
  padding: 100px 0;
}
.home-start .container {
  position: relative;
  max-width: 1180px;
  padding: 0 20px;
}
.home-start .container:before {
  content: "";
  position: absolute;
  top: -90px;
  left: -175px;
  display: block;
  width: 580px;
  height: 249px;
  background: url(./Assets/images/grid.png) repeat 0 0;
  display: block;
}
.home-start.blog .container:before{
  background: none;
}
.home-start .cnt {
  position: relative;
  max-width: 700px;
  display: block;
  z-index: 5;
}
.home-start .cnt.centered-btn .blue-btn{
  margin:190px 110px 0px;
}
.home-start .blog-list{
  position: relative;
}
.home-start .blog-item:first-child{
padding-top: 0px;
}
.home-start .blog-item{
  border-bottom:2px solid lightblue;
  padding:60px 20px;
}
.home-start .cnt-blog-left img,
.home-start .cnt-blog-right img{
  width: 90%;
  border-radius:4px;;
  border: 2px solid lightblue;
}
.home-start .cnt-blog-right {
  vertical-align: top;
  max-width: 35%;
  display: inline-block;
  text-align: right;
}
.home-start .cnt-blog-left {
  vertical-align: top;
  position: relative;
  max-width: 60%;
  display: inline-block;
  z-index: 5;
  margin-left: 20px;
}
.home-start .cnt-blog-left h2{
  font-size: 30px;
  font-weight:600;
}

.home-start .weight400{
  font-weight: 400;
  font-size: 28px;
  color: #45c1ee;
}
.home-start h2 {
  font-size: 65px;
  line-height: 65px;
  padding: 0 0 20px 0;
  text-shadow: none;
  width:60%;
}
.home-start.blog h2 {
  width:100%;
  line-height: 36px;
}
@media screen and (max-width:1400px) {  
  .home-start h2 {
    font-size: 55px;
    line-height: 55px;
  }
}
@media screen and (max-width:450px) {  
  .home-start h2 {
    font-size: 55px;
    line-height:55px;
  }
  .home-start.blog h2{
    font-size: 26px;
    line-height: 30px;
  }
}
.home-start p {
  padding: 0 0 25px 0;
  font-size: 24px;
  line-height: 28px;
  color: #313131;
}
.home-start ul {
  margin: 0 0 40px 0;
}
.home-start ul li {
  display: block;
  position: relative;
  padding: 0 0 0 30px;
  margin: 0 0 10px 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  color: #36cfe3;
  font-family: "Quicksand", serif;
}
.home-start ul li a {
  color: #36cfe3;
}
.home-start ul li a:hover {
  text-decoration: underline;
}
/* .home-start ul li:before {
  content: "✓";
  position: absolute;
  top: 20%;
  left: 0;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
} */
/* stationSlider */
.stationSlider.news h4{
  line-height: 1.2;
  text-align: center;
  color: #2f2f2f;
  font-weight: 600;
  padding: 40px 0 20px 0;
  font-family: "Quicksand", serif;
  font-size: 20pt;
}
.stationSlider .blog-item h2{
  font-size:30px;
  line-height: 1;
}
.stationSlider .blog-item{
  border-left:2px solid lightblue;
  padding-left:60px;
}
.blog-item span,
.stationSlider .blog-item span{
  font-weight:600;
} 
.stationSlider .blog-item a{
  margin-top:6px;
}
.stationSlider .blog-item img{
  border-radius:4px;
  border: 2px solid lightblue;
} 
.stationSlider .blog-item .float-right{ 
  float: right;
  height: 150px;
  display: inline-block;
  margin-left: 15px;
}

@media screen and (max-width:1024px) {  
  .home-start .cnt.centered-btn .blue-btn{
    margin:50px auto;
  }
  .home-start .cnt{
    text-align: center;
    max-width:100%;
  }
  .home-start p,
  .home-start h2{
    width:100%;
  }
}
@media only screen and (max-width: 989px) {
  .home-start .cnt-blog-left a{
    left: -20px;
    position: relative;
  }
  .home-start .cnt-blog-right,
  .home-start .cnt-blog-left{
    width:100%;
    max-width:100%;
    display: block;
  }  
  .home-start .cnt-blog-left img,
  .home-start .cnt-blog-right img{
    width:100%;
  }
  .stationSlider .blog-item .float-right{
    margin-left:0px;
    margin-bottom:15px;
  }
  .home-start {
    padding: 50px 0;
  }
  .home-start .cnt {
    margin: 0 auto;
    text-align: center;
  }
  .home-start ul {
    margin: 0 auto 40px;
    display: table;
    text-align: left;
  }
  .home-start .blue-btn {
    margin: 0 auto;
  }
}

/* Video Intro */
.video-intro {
  min-height: 610px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
}
.video-intro .container {
  max-width: 1180px;
  width: 100%;
}
.video-intro .cnt {
  max-width: 430px;
  display: block;
}
.video-intro.our-works {
  min-height: 350px;
}
.video-intro.our-works .cnt {
  max-width: unset;
}
.video-intro h2 {
  padding: 0 0 20px 0;
}
.video-intro h4 {
  padding: 0 0 35px 0;
  font-size: 30px;
  line-height: 40px;
  color: #41c4eb;
  font-family: "Quicksand", serif;
  font-style: italic;
  font-weight: normal;
  position: relative;
  margin-top: -10px;
}
.video-intro .play-btn {
  display: block;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  transition: all 0.2s;
  font-size: 0;
  line-height: 0;
  text-indent: -100%;
}
.video-intro .play-btn:before {
  content: "\f04b";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 30px;
  line-height: 80px;
  display: block;
  text-align: center;
  color: #ffffff;
  font-family: "FontAwesome", Georgia, serif;
  text-indent: 3px;
}
.video-intro .play-btn:hover {
  background-image: -moz-linear-gradient(
    -90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background-image: -webkit-linear-gradient(
    -90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background-image: -ms-linear-gradient(
    -90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
}
.video-intro.how-works {
  /* background-image: url(./Assets/images/intro_how_works_bg.jpg); */
  background-color: #ebedec;
}
/* .video-intro.our-works {
  background-image: url(./Assets/images/intro_our_works_bg.jpg);
  background-color: #f0f1f1;
} */
.video-intro.our-works {
  background-color: #f0f1f1;
}
.video-intro.about-us {
  background-color: #f0f1f1;
  min-height: 420px;
}
.video-intro.about-us .cnt div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 940px) {
  .video-intro .cnt {
    max-width: 200px;
  }
}

@media only screen and (max-width: 989px) {
  .video-intro.our-works{
    min-height:50px;
    padding:20px;;
  }
  .home-start .container:before {
    top:-40px;
  }
  .video-intro.about-us {
    /* background-image: url(./Assets/images/about_us_banner-mobile.png);
    background-image:none;
    background-repeat: no-repeat; */
    padding: unset;
    background-position: 70% 100%;
    min-height: 220px;
  }
  .video-intro.about-us .blue-btn{
    font-size: .75rem;
    padding: 0px 10px;
    margin-top: 15px;
  }
  .video-intro {
    min-height: 50px;
    padding: 50px 10px;
  }
  .video-intro h4 {
    font-size: 24px;
    display: none;
    line-height: 28px;
  }
  .bannerPopUp {
    display: none;
  }
  .works-intro .image-cnt {
    display: none;
  }
}

@media only screen and (max-width: 480px) {

  .works-slider.mtp .slick-track{
    height: 300px;
    overflow: hidden;
  }

  .video-intro h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .video-intro h2 {
    font-size: 26px !important;
    color: #424242;
    line-height: 30px;
    padding-bottom:0px;;
  }
  .video-intro .cnt {
    max-width: 140px;
  }
  .video-intro.about-us {
    padding: unset;
    background-position: 60% 100%;
    min-height: 140px;
  }
  
}
.home-start ul li {
  padding: 0 0 0 0px;
  font-size: 20px;
}
/* Creator Wp */
.creator-wp {
  padding: 130px 0 100px 0;
}
.creator-wp h2 {
  text-align: center;
  padding: 0 15px 120px 15px; /*190px*/
  font-size: 72px;
  line-height: 76px;
}
.creator-wp h2 span {
  font-size: 36px;
  line-height: 40px;
  display: block;
}
.creator-wp .row {
  position: relative;
  display: block;
  margin: 0 0 150px 0;
  width: 100%;
}
.creator-wp .row .cnt {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 50%;
  display: flex;
  justify-content: flex-start;
  background-image: -moz-linear-gradient(
    -5deg,
    rgb(248, 247, 247) 1%,
    rgb(255, 255, 255) 100%
  );
  background-image: -webkit-linear-gradient(
    -5deg,
    rgb(248, 247, 247) 1%,
    rgb(255, 255, 255) 100%
  );
  background-image: -ms-linear-gradient(
    -5deg,
    rgb(248, 247, 247) 1%,
    rgb(255, 255, 255) 100%
  );
}
.creator-wp .row .text {
  padding: 75px 60px;
  max-width: 570px;
  width: 100%;
  box-sizing: border-box;
}
.creator-wp .row:nth-child(even) .text {
  background-image: -webkit-linear-gradient(
    -5deg,
    rgb(255, 255, 255) 0%,
    rgb(248, 247, 247) 99%
  );
}
.creator-wp .row:nth-child(odd) .text {
  background-image: -webkit-linear-gradient(
    -5deg,
    rgb(248, 247, 247) 1%,
    rgb(255, 255, 255) 100%
  );
}
.creator-wp .row .text strong {
  font-weight: 500;
}
.creator-wp .row .image {
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 5;
  max-width: 820px;
  width: 100%;
  height: auto;
}
.creator-wp .row:nth-child(even) .cnt {
  right: 0;
  left: auto;
  justify-content: flex-end;
  text-align: right;
  background-image: -moz-linear-gradient(
    -5deg,
    rgb(255, 255, 255) 1%,
    rgb(248, 247, 247) 100%
  );
  background-image: -webkit-linear-gradient(
    -5deg,
    rgb(255, 255, 255) 1%,
    rgb(248, 247, 247) 100%
  );
  background-image: -ms-linear-gradient(
    -5deg,
    rgb(255, 255, 255) 1%,
    rgb(248, 247, 247) 100%
  );
}

@media only screen and (max-width: 1650px) {
  .creator-wp .row {
    margin: 0 0 50px 0;
  }
  .creator-wp .row .cnt {
    position: static;
    width: 100%;
    transform: translateX(0);
    justify-content: center;
  }
  .creator-wp .row:nth-child(2n) .cnt {
    justify-content: center;
  }
  .creator-wp .row .cnt .text {
    text-align: center;
    background-image: unset;
  }
}

@media only screen and (max-width: 1220px) {
  .creator-wp h2 {
    font-size: 52px;
    line-height: 56px;
  }
  .creator-wp {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 989px) {
  .creator-wp {
    padding: 25px 0;
  }
  .creator-wp h2 {
    padding-bottom: 25px;
    font-size: 42px;
    line-height: 46px;
  }
  .creator-wp h2 span {
    font-size: 22px;
    line-height: 26px;
  }
  .creator-wp .row {
    margin: 0 0 20px 0;
  }
  .creator-wp .row .text {
    padding: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .creator-wp h2 {
    font-size: 32px;
    line-height: 36px;
  }
}

/* Works Intro */
.works-intro {
  position: relative;
  padding: 0 0 160px 0;
  text-align: left;
}
.works-intro .container {
  max-width: 1180px;
  position: relative;
  z-index: 5;
}
.works-intro .cnt {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.works-intro .text {
  position: relative;
  padding: 170px 0 40px 0;
  max-width: 520px;
  width: 100%;
}
.works-intro .text:before {
  content: "";
  display: block;
  width: 570px;
  height: 240px;
  background: url(./Assets/images/grid.png) repeat center center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.works-intro .text h3 {
  padding: 0 80px 35px 0;
  font-size: 55px;
  line-height: 54px;
  font-family: "Quicksand", serif;
  font-weight: normal;
  color: #313131;
}
.works-intro .text p {
  padding: 0 80px 35px 0;
  font-size: 24px;
  line-height: 34px;
  font-weight: 400;
}
.works-intro .text .blue-text {
  font-size: 72px;
  line-height: 76px;
  color: #4cc6eb;
  font-weight: normal;
  font-style: italic;
  font-family: "Quicksand", serif;
}
.works-intro .text-cnt {
  position: relative;
  z-index: 5;
}
.works-intro .image {
  width: 560px;
  position: relative;
  display: flex;
  align-items: flex-end;
}
.works-intro .image .image-cnt {
  padding: 40px 0 0 0;
  position: relative;
  z-index: 5;
  max-width: 560px;
  margin: 0 auto;
}
.works-intro .image:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 220px;
  background: #edf9fd;
}
.works-intro.kitchesn__works-intro .image:before {
  display: none;
}
.works-intro .image img {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}
.works-intro .desktop-btn{
  margin-top: 20px;
}

@media only screen and (max-width: 980px) {
  .works-intro {
    padding-bottom: 60px;
  }
  .works-intro .text {
    padding-top: 80px;
  }
  .works-intro .text h3 {
    font-size: 40px;
    line-height: 44px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .works-intro .text p {
    font-size: 20px;
    line-height: 24px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .works-intro .text .blue-text {
    font-size: 50px;
    line-height: 54px;
  }
}

@media only screen and (max-width: 989px) {
  .works-intro .cnt {
    flex-wrap: wrap;
  }
  .works-intro .text {
    max-width: 100%;
    text-align: center;
  }
  .works-intro .image {
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .margin-auto-mobile{
    margin: auto;
  } 
}

@media only screen and (max-width: 480px) {
  .works-intro .text h3 {
    font-size: 42px;
    line-height: 34px;
  }
  .works-intro .text p {
    font-size: 16px;
    line-height: 20px;
  }
  .works-intro .text .blue-text {
    font-size: 35px;
    line-height: 39px;
  }
}

/* Projects */
.projects {
  position: relative;
  padding: 0 0 130px 0;
  text-align: center;
}
.projects .container {
  max-width: 1800px;
  position: relative;
  z-index: 5;
}
.projects ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.projects ul li {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 32.2%;
  padding: 0 0 20px 0;
}
.projects ul li img {
  display: block;
  width: 100%;
  height: auto;
}
.projects ul li .title {
  padding: 80px 20px;
  border: solid 2px #4cc6eb;
  font-size: 60px;
  line-height: 64px;
  color: #2f2f2f;
  text-align: center;
  font-family: "Quicksand", serif;
}
.projects ul li .title span {
  display: block;
  font-size: 30px;
  line-height: 34px;
}
.projects ul li .blue-btn {
  margin: 0.5em auto;
}

@media only screen and (max-width: 1160px) {
  .projects ul li .title {
    font-size: 40px;
    line-height: 44px;
    padding: 30px 20px;
  }
  .projects ul li .title span {
    font-size: 25px;
    line-height: 29px;
  }
}

@media only screen and (max-width: 840px) {
  .projects {
    padding-bottom: 50px;
  }
  .projects ul li .title {
    font-size: 32px;
    line-height: 36px;
    padding: 20px 15px;
  }
  .projects ul li .title span {
    font-size: 22px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 670px) {
  .projects ul li .title {
    font-size: 26px;
    line-height: 30px;
    padding: 10px;
  }
  .projects ul li .title span {
    font-size: 18px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 540px) {
  .projects ul li .title {
    font-size: 14px;
    line-height: 18px;
    padding: 5px;
  }
  .projects ul li .title span {
    font-size: 12px;
    line-height: 16px;
  }
  .projects ul li .blue-btn {
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
  }
}

/* Works Text */
.works-text {
  position: relative;
  background: #f2f2f2;
  padding: 110px 0 120px 0;
  text-align: center;
}
.works-text .container {
  max-width: 1180px;
  position: relative;
  z-index: 5;
}
.works-text h2 {
  padding: 0 0 35px 0;
  font-family: "Quicksand", serif;
}
.works-text p {
  font-size: 30px;
  line-height: 48px;
}

@media only screen and (max-width: 989px) {
  .works-text {
    padding: 50px 0;
  }
  .works-text p {
    font-size: 18px;
    line-height: 22px;
  }
}

/* Works Images */
.works-images {
  position: relative;
  padding: 90px 0 105px 0;
  text-align: center;
}
.works-images .container {
  max-width: 1180px;
  position: relative;
  z-index: 5;
}
.works-images ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.works-images ul li {
  display: block;
  padding: 0 10px;
  max-width: 50%;
}
.works-images ul li img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 980px) {
  .works-images {
    padding: 50px 0;
  }
  .works-images ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.MuiFab-extended.full-width {
  width: 100%;
}

.MuiFab-primary.full-width.cta {
  background: linear-gradient(90deg, rgb(229 148 0) 0%, rgb(255 169 11) 100%);
}
.MuiFab-primary.full-width.ctaB {
  background: linear-gradient(90deg, rgb(20 47 74) 0%, rgb(22 84 144) 100%);
}
